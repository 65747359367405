import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'location_re';
const namespace = 'ui-pdp-icon ui-pdp-icon--location-re';

const IconLocationRE = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconLocationRE.propTypes = {
  className: string,
};

IconLocationRE.defaultProps = {
  className: null,
};

IconLocationRE.ICON_ID = ICON_ID;

export default React.memo(IconLocationRE);
export { IconLocationRE };
